import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

/*
  PrimeVue assumes a body font size of 14px for their Figma UI kit and demo/documentation site
  this means that 1rem would equal 14px

  Our project uses the much more common 16px for body font-size (which is also the tailwind default)
  because of this, out of the box PrimeVue components used in our project appear much larger than intended,
  which results in having to manually override the CSS and/or apply a size="small" prop to input and button
  components in order to get them to the desired size

  This function compensates for this by taking the original rem size from the Aura theme, and scaling it down so that
  it would be the same size as if we had a 14px body font size
*/
const remapRem = (rem: number, matchBodyFontSize: number = 14) => `${(rem * matchBodyFontSize) / 16}rem`;

export const Apparatix = definePreset(Aura, {
  semantic: {
    primary: {
      50: '{indigo.50}',
      100: '{indigo.100}',
      200: '{indigo.200}',
      300: '{indigo.300}',
      400: '{indigo.400}',
      500: '{indigo.500}',
      600: '{indigo.600}',
      700: '{indigo.700}',
      800: '{indigo.800}',
      900: '{indigo.900}',
      950: '{indigo.950}',
    },
    iconSize: remapRem(1),
    formField: {
      paddingX: remapRem(0.75),
      paddingY: remapRem(0.5),
      sm: {
        fontSize: remapRem(0.875),
        paddingX: remapRem(0.625),
        paddingY: remapRem(0.375),
      },
      lg: {
        fontSize: remapRem(1.125),
        paddingX: remapRem(0.875),
        paddingY: remapRem(0.625),
      },
    },
  },
  components: {
    button: {
      root: {
        iconOnlyWidth: remapRem(2.5),
        badgeSize: remapRem(1),
        gap: remapRem(0.5),
      },
      css: () => `
        .p-button {
          font-size: ${remapRem(1)};
        }
      `,
    },
    tabs: {
      tabpanel: {
        padding: '0.875rem 0 1.25rem 0',
      },
    },
    datatable: {
      css: () => `
        .no-left-padding .p-datatable-tbody > tr > td:first-child,
        .no-left-padding .p-datatable-thead > tr > th:first-child {
          padding-left: 0;
        }
      `,
    },
    splitbutton: {
      css: ({ dt }) => `
        .p-splitbutton-ai > .p-button-secondary {
          background: ${dt('ai.secondary.background')};
          border-color: transparent;
        }
        .p-splitbutton-ai:hover > .p-button-secondary:hover {
          background: ${dt('ai.secondary.hover.background')};
        }
        .p-splitbutton-ai:active > .p-button-secondary:active {
          background: ${dt('ai.secondary.active.background')};
        }
      `,
    },
  },
  extend: {
    ai: {
      primary: {
        background: 'var(--ai-primary-background) padding-box, linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) border-box',
        hoverBackground: 'var(--ai-primary-background-hover) padding-box, linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) border-box',
        activeBackground: 'var(--ai-primary-background-active) padding-box, linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)) border-box',
        borderColor: 'transparent',
        activeBorderColor: 'transparent',
        hoverBorderColor: 'transparent',
      },
      secondary: {
        background: 'linear-gradient(white, white) padding-box, var(--ai-border-gradient) border-box',
        hoverBackground: 'var(--ai-secondary-background-hover) padding-box, var(--ai-border-gradient) border-box',
        activeBackground: 'var(--ai-secondary-background-active) padding-box, var(--ai-border-gradient) border-box',
        borderColor: 'transparent',
        hoverBorderColor: 'transparent',
        activeBorderColor: 'transparent',
      },
    },
  },
});
